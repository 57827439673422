import Big from "big.js";
import { numberWithCommas } from "@/utils/string";
import { acnPaymentType } from "@/enums";

export default function useACNRates() {
    function getCommission(job, specialistRates) {
        if (job.paymentType == acnPaymentType.COMMISSION) {
	     let rate =  new Big(job.rates).round(2).toFixed(2);
            //let rate = getJobCalculatedRate(specialistRates, job.rates);
            return `${rate}%`;;
        }
        return `Fixed Rate RM${job.rates}`;
    }

    function getJobCalculatedRate(specialistRates, jobRates) {
        let spcDecimal = new Big(specialistRates).div(100);
        return new Big(jobRates).times(spcDecimal).round(2).toFixed(2);
    }

    function getJobEstimatedAmount({
        listingCommission = 0,
        jobRates = 0,
        specialistCommission = 0,
        salePrice = 0
    }) {
        if (listingCommission == 0) {
            return "Commission rate for the group has yet to be set.";
        }
        let jobCommDecimal = new Big(jobRates).div(100);
        let listingCommDecimal = new Big(listingCommission).div(100);
	let spcCommDecimal = 1; //Need to Check
	//if(subJob){
        // spcCommDecimal = new Big(specialistCommission).div(100);
	//}
        let listingPrice = new Big(salePrice);
        let amount = new Big(listingPrice)
            .times(jobCommDecimal)
            .times(listingCommDecimal).times(spcCommDecimal);
       //return `RM ${amount}-${listingPrice}-${jobCommDecimal}-${listingCommDecimal}-${spcCommDecimal}`;
       return `RM${numberWithCommas(amount.round(2).toFixed(2))}`;
    }

    return {
        getCommission,
        getJobCalculatedRate,
        getJobEstimatedAmount
    };
}
